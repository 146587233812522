<template>
  <div id="app" class="url">
    <!-- <el-row>
      <el-col :span="24">
        <el-page-header @back="goBack" content="移动端权限类型分配"> </el-page-header>
      </el-col>
    </el-row> -->

    <el-row style="margin:  10px 0">
      <el-button type="success" icon="el-icon-check" @click="dialogVisible = true">选择公司</el-button>
      <el-button :disabled="usable" type="success" icon="el-icon-plus" @click="dialogVisible1 = true">添加人员</el-button>
    </el-row>

    <!-- 公司信息列表 -->
    <el-dialog title="公司信息" :visible.sync="dialogVisible" width="40%" append-to-body :close-on-click-modal="false" style="background:rgba(0,0,0,0.5)">
      <!-- default-expand-all -->
      <!-- :check-strictly="true" -->
      <div style="height: 260px; overflow-y: scroll">
        <el-tree :check-strictly="true" :data="allCompanyList" show-checkbox node-key="id" ref="tree" highlight-current :props="defaultProps" accordion>
        </el-tree>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="getCheckedKeys">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 添加人员选择 弹框 -->
    <el-dialog title="添加人员" :visible.sync="dialogVisible1" width="30%" :close-on-click-modal="false" style="background:rgba(0,0,0,0.5)">
      <div>
        <table>
          <tr>
            <td>
              <el-tag style="font-size: 16px; margin: 0 10px 10px 0">姓名：</el-tag>
            </td>
            <td style="width: 100%">
              <el-input v-model="personnel.name" placeholder="请输入姓名"></el-input>
            </td>
          </tr>
          <tr>
            <td>
              <el-tag style="font-size: 16px; margin: 0 10px 0 0">电话：</el-tag>
            </td>
            <td>
              <el-input v-model="personnel.phone" placeholder="请输入电话" type="number" style="width: 100%"></el-input>
            </td>
          </tr>
        </table>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="addPersonnel">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 给添加的人  添加权限  弹框-->
    <el-dialog title="编辑用户权限" :visible.sync="dialogVisible2" :close-on-click-modal="false" width="40%" style="background:rgba(0,0,0,0.5)">
      <div style="height: 200px; overflow-y: scroll">
        <el-tree :data="personnelJurisList" show-checkbox node-key="uuid" ref="tree1" highlight-current :props="defaultProps1" accordion>
        </el-tree>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="getCheckedNodes">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 公司对应的 人员信息列表 -->
    <el-table border :data="personnelInfoList" style="width: 100%" height="700px">
      <el-table-column align="center" label="序号" type="index" width="80">
      </el-table-column>
      <el-table-column align="center" prop="name" label="姓名">
      </el-table-column>
      <el-table-column align="center" prop="phone" label="电话">
      </el-table-column>

      <el-table-column align="center" fixed="right" label="编辑用户权限" width="180">
        <!-- 添加权限 -->
        <template slot-scope="scope">

          <el-button type="primary" plain size="mini" @click="editPersonnel(scope.row)">编辑</el-button>
          <el-button type="danger" plain size="mini" @click="delPersonnel(scope.row)">删除</el-button>
        </template>

      </el-table-column>

    </el-table>
  </div>
</template>

<script>
// 公司系统
import companySystem from "@/api/managementApi/CompanyInfo";
// import jurisApi from "@/api/managementApi/PermissionSysManage";
// import jurisSmallSystem from "@/api/managementApi/PermissionTypeManage";
// 大小系统
import jurisBigSmallSys from "@/api/managementApi/JurisBigSmallSys";

// 公司权限分配操作
import jurisCompanyUser from "@/api/managementApi/JurisCompanyUser"


export default {
  name: "PermissionTypeAllot",
  data() {
    return {
      defaultChecked: [],
      bigSmallSystemInfo: [],
      a: {},
      JurisUtilInclusionRelation: {},
      ids: [],
      phone: "",
      editPhone: "",

      dialogVisible: false, // 选择公司
      dialogVisible1: false, // 添加人员
      dialogVisible2: false, // 编辑用户权限
      usable: true,
      allCompanyList: [], // 所有公司
      personnelJurisList: [], //编辑用户权限树
      peopleInfoList: [], // 通过公司id查人员列表
      checkedKeys: "", // 被选择的公司id

      personnel: {
        name: "",
        phone: null,
      },
      defaultProps: {
        children: "smallCompanyList", // 子公司节点
        label: "name", // 公司名
      },
      defaultProps1: {
        children: "jurisSmallSystems", // 子公司节点
        label: "smallSystemName", //
      },

      // 公司对应人员列表
      personnelInfoList: [],

    };
  },
  created() {
    this.getEditPersonnelJuris(0);
    this.getAllCompanyList(); // 查询到所有公司列表
  },

  methods: {
    // 返回上一级
    goBack() {
      this.$router.push("/Select");
    },

    editPersonnel(row) {
      this.defaultChecked = []
      this.editPhone = row.phone
      this.dialogVisible2 = true
      this.getAllCommonJurisList()
    },

    // 查询所有公司结构
    getAllCompanyList() {
      companySystem.getAllCompanyStructure().then((res) => {
        this.allCompanyList = res.data.data.list;
      });
    },
    // 选择公司  确定按钮
    getCheckedKeys() {
      this.checkedKeys = this.$refs.tree.getCheckedKeys()

      if (this.$refs.tree.getCheckedKeys() == "") {
        alert("请选择公司");
      } else {
        this.dialogVisible = false;
        this.getCompanyPersonnelInfo(this.checkedKeys);
        this.usable = false; // 添加人员 按钮可用
      }

    },

    // 通过公司id 获取人员列表信息
    getCompanyPersonnelInfo(checkedKeys) {
      jurisCompanyUser.getJurisCompanyUser(checkedKeys).then((res) => {
        this.personnelInfoList = res.data.data.list;
      });
    },

    // 编辑用户权限 树  查所有
    getEditPersonnelJuris() {
      jurisBigSmallSys.getSystemInfo(0).then((res) => {
        this.personnelJurisList = res.data.data.list;
        for (let i = 0; i < this.personnelJurisList.length; i++) {
          this.personnelJurisList[i]["smallSystemName"] =
            this.personnelJurisList[i]["bigSystemName"];
        }

        for (let i in this.personnelJurisList) {
          this.personnelJurisList[i]["uuid"] = this.personnelJurisList[i].bigSystemId
          for (let j in this.personnelJurisList[i].jurisSmallSystems) {
            this.personnelJurisList[i].jurisSmallSystems[j]["uuid"] = "" + this.personnelJurisList[i].bigSystemId + "_" + this.personnelJurisList[i].jurisSmallSystems[j].id
          }
        }
      });
    },

    // 传编辑用户权限选中的对象id
    getCheckedNodes() {
      this.bigSmallSystemInfo = []
      this.a = {}

      this.ids = this.checkedKeys
      this.phone = this.editPhone

      for (let i in this.$refs.tree1.getCheckedNodes()) {
        if (this.$refs.tree1.getCheckedNodes()[i]["uuid"].indexOf("_") != -1) {
          let bigId = this.$refs.tree1.getCheckedNodes()[i]["uuid"].split("_")[0]
          let smallId = this.$refs.tree1.getCheckedNodes()[i]["uuid"].split("_")[1]
          if (this.a[bigId] == null) {
            this.a[bigId] = this.bigSmallSystemInfo.length
            this.bigSmallSystemInfo.push({
              bigSystemId: bigId,
              jurisSmallSystems: [
              ]
            })
          }
          this.bigSmallSystemInfo[this.a[bigId]].jurisSmallSystems.push({ id: smallId })
        }
      }
      this.JurisUtilInclusionRelation = { ids: this.ids, phone: this.phone, bigSmallSystemInfo: this.bigSmallSystemInfo }

      this.addJurisRelation();
    },

    // -----增加——>util_inclusion_relation-----
    addJurisRelation() {
      jurisCompanyUser
        .addJurisUtilInclusionRelation(this.JurisUtilInclusionRelation).then(() => {
          this.dialogVisible2 = false;
        })
    },

    // 添加人员  确定按钮
    addPersonnel() {
      if (this.checkedKeys == '') {
        alert('请选择公司')
      } else
        if (this.personnel.name == "" || this.personnel.phone == "" || this.personnel.phone == null) {

          this.$message.error("姓名和电话不能为空");
        } else {
          jurisCompanyUser.addJurisCompanyUser({
            phone: this.personnel.phone,
            name: this.personnel.name,
            ids: this.checkedKeys
          }).then(() => {
            this.dialogVisible1 = false;
            this.personnel.name = "";
            this.personnel.phone = "";
            // this.getAllCompanyList();
            // 重新加载人员列表
            this.getCompanyPersonnelInfo(this.checkedKeys);
            this.personnel.name = '';
            this.personnel.phone = null
          });
        }
    },

    // 删除        （根据公司id查人员列表）
    delPersonnel(row) {

      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        jurisCompanyUser.deleteJurisCompanyUser(row.phone, this.checkedKeys).then((res) => {
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //重新加载人员列表
          this.getCompanyPersonnelInfo(this.checkedKeys);
        });
      });
    },

    // 查看所有权限
    getAllCommonJurisList() {
      this.defaultChecked = []
      jurisCompanyUser.getAllCommonJuris(this.checkedKeys.toString(), this.editPhone).then((res) => {
        let defaultCheckedls = []
        for (let i in res.data.data.list) {
          for (let j in res.data.data.list[i].smallSystemInfos) {
            defaultCheckedls.push("" + res.data.data.list[i].bigSystemId + "_" + res.data.data.list[i].smallSystemInfos[j].id)
          }
        }
        this.defaultChecked = defaultCheckedls
        this.$refs.tree1.setCheckedKeys(this.defaultChecked)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
#app{
  box-sizing: border-box;
  padding: 0 5px;
}
.edit-box {
  margin-bottom: 20px;
}
.sysType {
  text-shadow: 2px 2px 1px #ccc;
  font-size: 18px;
  font-weight: 600;
  color: #666;
}
.permissionType {
  display: flex;
  line-height: 30px;
}
.permissionType div {
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 2px 2px 3px #999;
  font-size: 16px;
  margin: 5px 5px 0;
  padding: 0px 5px;
  display: inline-block;
}
</style>