import request from '@/../utils/request'
const api_name = '/iotechserver/jurisCompanyUser'

export default {
    // 通过公司id返回人员列表
    getJurisCompanyUser(ids) {
        return request({
            url: `${api_name}/getJurisCompanyUser/${ids}`,
            method: 'get',
        })
    },

    // 删除用户
    deleteJurisCompanyUser(phone, ids) {
        return request({
            url: `${api_name}/deleteJurisCompanyUser/${phone}/${ids}`,
            method: 'delete'
        })
    },

    // 添加用户
    addJurisCompanyUser(jurisCompanyUserVo) {
        return request({
            url: `${api_name}/addJurisCompanyUser`,
            method: 'post',
            data: jurisCompanyUserVo
        })
    },



    // -----增加——>util_inclusion_relation-----
    addJurisUtilInclusionRelation(JurisUtilInclusionRelation) {
        return request({
            url: `${api_name}/addJurisUtilInclusionRelation`,
            method: 'post',
            data: {
                "ids": JurisUtilInclusionRelation["ids"],
                "phone": JurisUtilInclusionRelation["phone"],
                "bigSmallSystemInfo": JurisUtilInclusionRelation["bigSmallSystemInfo"]
            }
        })
    },

    //  查看所有权限
    getAllCommonJuris(ids, phone) {
        return request({
            url: `${api_name}/getAllCommonJuris/${ids}/${phone}`,
            method: 'get',
        })
    }
}