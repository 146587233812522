<template>
  <div id="app" class="url">
    <!-- <el-row>
      <el-col :span="24">
        <el-page-header @back="goBack" content="平台端权限类型分配"> </el-page-header>
      </el-col>
    </el-row> -->

    <el-row style="margin: 10px 0">
      <el-button type="success" icon="el-icon-check" @click="dialogVisible = true">选择公司</el-button>
      <el-button :disabled="usable" type="success" icon="el-icon-plus" @click="addAccount">添加账号</el-button>
      <el-button :disabled="usable" type="success" @click="distribution">分配设备类型</el-button>
    </el-row>
    <!-- 公司信息列表 -->
    <el-dialog title="公司信息" :visible.sync="dialogVisible" width="40%" append-to-body :close-on-click-modal="false" style="background:rgba(0,0,0,0.5)">
      <div style="height: 260px; overflow-y: scroll">
        <el-tree :check-strictly="true" :data="allCompanyList" show-checkbox node-key="id" ref="tree" highlight-current :props="defaultProps" accordion>
        </el-tree>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="getCheckedKeys">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 添加人员选择 弹框 -->
    <el-dialog title="添加账号" :visible.sync="dialogVisible1" width="30%" :close-on-click-modal="false" style="background:rgba(0,0,0,0.5)">
      <div>
        <!-- <table>
          <tr>
            <td>
              <el-tag style="font-size: 16px; margin: 0 10px 10px 0"
                >用户名：</el-tag
              >
            </td>
            <td style="width: 100%">
              <el-input
                v-model="personnel.name"
                placeholder="请输入用户名"
              ></el-input>
            </td>
          </tr>
          <tr>
          </tr>
        </table> -->
        <el-select v-model="personnel.name" filterable placeholder="请选择账号" style="width:100%">
          <el-option v-for="item in AllUserNameList" :label="item" :value="item">
          </el-option>
        </el-select>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="addPersonnel">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 给添加的人  添加权限  弹框-->
    <el-dialog title="编辑用户权限" :visible.sync="dialogVisible2" :close-on-click-modal="false" width="40%" style="background:rgba(0,0,0,0.5)">
      <div style="height: 200px; overflow-y: scroll">
        <el-tree :data="personnelJurisList" show-checkbox node-key="uuid" ref="tree1" highlight-current :props="defaultProps1" accordion>
        </el-tree>
      </div>

      <div slot="footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="getCheckedNodes">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 显示全部设备类型 -->
    <el-dialog title="设备类型分配" :visible.sync="showAlldeviceType" width="45%" style="background:rgba(0,0,0,0.5)" :before-close="dialogBeforeClose">

      <div class="device-type">
        <div class="device-type-item">
          <span class="item1" @click="selectAll">全选</span>
          <span> / </span>
          <span class="item2" @click="selectInvert">取消</span></span>
        </div>
        <div style="margin-top:30px">
          <el-checkbox v-model="checkedList" v-for="item in allDeviceTypeList" :key="item.id" :label="item.id" border size="medium" style="margin:5px 10px">{{item.name}}</el-checkbox>
        </div>
      </div>

      <div slot="footer">
        <el-button @click="cancle">取 消</el-button>
        <el-button type="primary" @click="submitDeviceType">确 定</el-button>
      </div>
    </el-dialog>

    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="账号管理" name="first">
        <!-- 公司对应的 人员信息列表 -->
        <el-table :data="personnelInfoList" style="width: 100%" height="700px">
          <el-table-column align="center" label="序号" type="index" width="80">
          </el-table-column>
          <el-table-column align="center" prop="name" label="用户名">
          </el-table-column>
          <el-table-column align="center" fixed="right" label="编辑用户权限" width="180">
            <!-- 添加权限 -->
            <template slot-scope="scope">
              <el-button type="primary" plain size="mini" @click="editPersonnel(scope.row)">编辑</el-button>
              <el-button type="danger" plain size="mini" @click="delPersonnel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <el-tab-pane label="设备类型分配" name="second">
        <div style="height:500px;">
          <el-tag type="primary" v-for="(item,index) in showCheckedList" :key="index" style="margin:10px;font-size:16px;user-select:none">{{item}}</el-tag>
        </div>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
// 公司系统
import companySystem from "@/api/managementApi/CompanyInfo";
// 大小系统
import jurisBigSmallSys from "@/api/managementApi/JurisBigSmallSys";
// 公司权限分配操作
import jurisCompanyAccount from "@/api/managementApi/JurisCompanyAccount"
import User from "@/api/manageApi/User"

import DeviceTypeManagement from '@/api/manageApi/DeviceTypeManagement'



export default {
  name: "PCPermissionTypeAllot",
  data() {
    return {
      activeName: 'first',
      defaultChecked: [],
      bigSmallSystemInfo: [],
      a: {},
      JurisUtilInclusionRelation: {},
      ids: [],
      name: "",
      editName: "",

      allDeviceTypeList: [], // 所有设备类型
      checkedList: [],
      showCheckedList: [],


      showAlldeviceType: false, // 设备类型分配
      dialogVisible: false, // 选择公司
      dialogVisible1: false, // 添加人员
      dialogVisible2: false, // 编辑用户权限
      usable: true,
      allCompanyList: [], // 所有公司
      personnelJurisList: [], //编辑用户权限树
      peopleInfoList: [], // 通过公司id查人员列表
      checkedKeys: "", // 被选择的公司id

      personnel: {
        name: "",
      },
      defaultProps: {
        children: "smallCompanyList", // 子公司节点
        label: "name", // 公司名
      },
      defaultProps1: {
        children: "jurisSmallSystems", // 子公司节点
        label: "smallSystemName", //
      },

      // 公司对应人员列表
      personnelInfoList: [],
      AllUserNameList: [],//所有账号的名称集合，排除了juris的手机用户
    };
  },
  created() {
    this.getEditPersonnelJuris(0);
    this.getAllCompanyList(); // 查询到所有公司列表
    this.getAllUserInfo()

    this.getAllDeviceType();
  },

  methods: {
    //   根据公司id查设备类型
    async getDeviceTypeByCompanyId() {
      const { data: res } = await DeviceTypeManagement.getDeviceTypeByCiIds(this.checkedKeys.toString())
      console.log(res.data.deviceTypeSet);
      console.log(this.allDeviceTypeList);


      this.checkedList = res.data.deviceTypeSet;

      //   for (let i = 0; i < res.data.deviceTypeSet.length; i++) {
      //     console.log(res.data.deviceTypeSet[i]);

      //   }
      this.showCheckedList = [];

      for (let i = 0; i < res.data.deviceTypeSet.length; i++) {
        for (let j = 0; j < this.allDeviceTypeList.length; j++) {
          if (res.data.deviceTypeSet[i] == this.allDeviceTypeList[j].id) {
            this.showCheckedList.push(this.allDeviceTypeList[j].name)
          }
        }
      }

    },
    // 分配设备类型按钮操作
    distribution() {
      this.showAlldeviceType = true;
      this.activeName = 'second';
    },
    // 添加账号按钮操作
    addAccount() {
      this.dialogVisible1 = true
      this.activeName = 'first';
    },


    // 查全部设备类型
    async getAllDeviceType() {
      const { data: res } = await DeviceTypeManagement.getAllDeviceType();
      this.allDeviceTypeList = res.data.list;
      console.log(this.allDeviceTypeList);
    },

    // 分配设备类型确定按钮
    submitDeviceType() {
      this.showCheckedList = [];
      let obj = {};
      obj.ciId = this.checkedKeys.toString();
      obj.valueList = this.checkedList;

      if (this.checkedList.length == 0) {
        //   删除
        DeviceTypeManagement.deleteDeviceTypeByCiId(obj.ciId, obj.valueList).then(res => {
          console.log(res);
          if (res.data.message == "删除成功") {
            this.$message.success("操作成功!")
            this.getDeviceTypeByCompanyId()
            this.showAlldeviceType = false;
            DeviceTypeManagement.startDeviceNumber()
          }
        })
      } else {
        //   修改
        DeviceTypeManagement.addDeviceTypeByCiId(obj.ciId, obj.valueList).then(res => {
          console.log(res);
          if (res.data.message == "添加成功") {
            this.$message.success("操作成功!")
            this.getDeviceTypeByCompanyId()
            this.showAlldeviceType = false;
            DeviceTypeManagement.startDeviceNumber()
          }
        })
      }
    },
    // 弹框取消按钮
    cancle() {
      this.showAlldeviceType = false
      this.getDeviceTypeByCompanyId()
    },
    // 弹框关闭按钮
    dialogBeforeClose() {
      this.showAlldeviceType = false;
      this.getDeviceTypeByCompanyId()
    },
    // 反选
    selectInvert() {
      this.checkedList = [];
    },
    // 全选
    selectAll() {
      this.checkedList = [];
      for (let i = 0; i < this.allDeviceTypeList.length; i++) {
        this.checkedList.push(this.allDeviceTypeList[i].id)
      }
      console.log(this.checkedList);
    },


    handleClick() {
      console.log(this.activeName);
    },

    // 返回上一级
    goBack() {
      this.$router.push("/Select");
    },
    //获取所有的账号名称,排除juris的手机用户
    getAllUserInfo() {
      User.getAllUserInfo().then(res => {
        this.AllUserNameList = res.data.data.userNameList;
      })
    },
    // 查询所有公司结构
    getAllCompanyList() {
      companySystem.getAllCompanyStructure().then((res) => {
        this.allCompanyList = res.data.data.list;
      });
    },
    // 选择公司  确定按钮
    getCheckedKeys() {
      this.checkedKeys = this.$refs.tree.getCheckedKeys()
      console.log(this.checkedKeys);
      this.getDeviceTypeByCompanyId()

      if (this.$refs.tree.getCheckedKeys() == "") {
        alert("请选择公司");
      } else {
        this.dialogVisible = false;
        this.getCompanyPersonnelInfo(this.checkedKeys);
        this.usable = false; // 添加人员 按钮可用
      }
      // alert(this.checkedKeys.slice(0,1));
      // alert(this.$refs.tree.getCheckedKeys());
    },

    // 通过公司id 获取人员列表信息
    getCompanyPersonnelInfo(checkedKeys) {
      jurisCompanyAccount.getJurisCompanyAccount(checkedKeys).then((res) => {
        console.log(res);
        this.personnelInfoList = res.data.data.AccountList;
      });
    },

    // 编辑用户权限 树  查所有
    getEditPersonnelJuris() {
      jurisBigSmallSys.getSystemInfo(0).then((res) => {
        this.personnelJurisList = res.data.data.list;
        for (let i = 0; i < this.personnelJurisList.length; i++) {
          this.personnelJurisList[i]["smallSystemName"] =
            this.personnelJurisList[i]["bigSystemName"];
        }

        for (let i in this.personnelJurisList) {
          this.personnelJurisList[i]["uuid"] = this.personnelJurisList[i].bigSystemId
          for (let j in this.personnelJurisList[i].jurisSmallSystems) {
            this.personnelJurisList[i].jurisSmallSystems[j]["uuid"] = "" + this.personnelJurisList[i].bigSystemId + "_" + this.personnelJurisList[i].jurisSmallSystems[j].id
          }
        }
      });
    },
    // 传编辑用户权限选中的对象id
    getCheckedNodes() {
      this.bigSmallSystemInfo = []
      this.a = {}
      this.ids = this.checkedKeys

      for (let i in this.$refs.tree1.getCheckedNodes()) {
        if (this.$refs.tree1.getCheckedNodes()[i]["uuid"].indexOf("_") != -1) {
          let bigId = this.$refs.tree1.getCheckedNodes()[i]["uuid"].split("_")[0]
          let smallId = this.$refs.tree1.getCheckedNodes()[i]["uuid"].split("_")[1]
          console.log(this.a);
          if (this.a[bigId] == null) {
            this.a[bigId] = this.bigSmallSystemInfo.length
            this.bigSmallSystemInfo.push({
              bigSystemId: bigId,
              jurisSmallSystems: [
              ]
            })
          }
          this.bigSmallSystemInfo[this.a[bigId]].jurisSmallSystems.push({ id: smallId })
        }
      }
      this.JurisUtilInclusionRelation = {
        ids: this.ids,
        bigSmallSystemInfo: this.bigSmallSystemInfo
      }

      this.addJurisRelation();
    },

    // -----增加——>util_inclusion_relation-----
    addJurisRelation() {
      this.JurisUtilInclusionRelation.name = this.name,
        jurisCompanyAccount
          .addJurisUtilInclusionRelation(this.JurisUtilInclusionRelation).then((res) => {
            this.dialogVisible2 = false;
            this.$message({
              type: res.data.success ? "success" : "warning",
              message: res.data.message,
            });
          })
    },

    // 添加账号  确定按钮
    addPersonnel() {
      if (this.checkedKeys == '') {
        alert('请选择公司')
      } else
        if (this.personnel.name == "") {
          this.$message.error("用户名不能为空");
        } else {
          jurisCompanyAccount.addJurisCompanyAccount({
            name: this.personnel.name,
            ids: this.checkedKeys
          }).then((res) => {
            this.dialogVisible1 = false;
            this.personnel.name = "";
            this.$message({
              type: res.data.success ? "success" : "warning",
              message: res.data.message,
            });
            console.log(res);
            // this.getAllCompanyList();
            // 重新加载人员列表
            this.getCompanyPersonnelInfo(this.checkedKeys);
            this.personnel.name = '';
          });
        }
    },

    // 删除        （根据公司id查人员列表）
    delPersonnel(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        jurisCompanyAccount.deleteJurisCompanyAccount(row.name, this.checkedKeys).then((res) => {
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //重新加载人员列表
          this.getCompanyPersonnelInfo(this.checkedKeys);
        });
      });
    },
    editPersonnel(row) {
      this.name = row.name
      this.defaultChecked = []
      this.dialogVisible2 = true
      this.getAllCommonJurisList()
    },
    // 查看所有权限
    getAllCommonJurisList() {
      this.defaultChecked = []
      jurisCompanyAccount.getAllCommonJuris(this.checkedKeys.toString(), this.name).then((res) => {
        console.log(res);
        let defaultCheckedls = []
        for (let i in res.data.data.list) {
          for (let j in res.data.data.list[i].smallSystemInfos) {
            defaultCheckedls.push("" + res.data.data.list[i].bigSystemId + "_" + res.data.data.list[i].smallSystemInfos[j].id)
          }
        }
        this.defaultChecked = defaultCheckedls
        this.$refs.tree1.setCheckedKeys(this.defaultChecked)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
#app {
  box-sizing: border-box;
  padding: 0 5px;
}
.edit-box {
  margin-bottom: 20px;
}
.sysType {
  text-shadow: 2px 2px 1px #ccc;
  font-size: 18px;
  font-weight: 600;
  color: #666;
}
.permissionType {
  display: flex;
  line-height: 30px;
}
.permissionType div {
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 2px 2px 3px #999;
  font-size: 16px;
  margin: 5px 5px 0;
  padding: 0px 5px;
  display: inline-block;
}
/deep/.el-dialog__title {
  font-size: 22px;
  font-weight: 600;
  color: rgb(2, 37, 37);
}
/deep/.el-dialog__title::before {
  content: "丨";
  font-weight: 700;
  font-size: 20px;
  color: rgb(2, 37, 37);
}
::-webkit-scrollbar {
  display: none;
}

.device-type {
  position: relative;
  height: 350px;
  overflow: scroll;
  background: #fff;

  .device-type-item {
    height: 30px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    width: 43%;
    // border: 1px solid red;
    position: fixed;
    text-align: right;
  }
  .device-type-item .item1:hover {
    color: rgb(127, 208, 255);
  }
  .device-type-item .item2:hover {
    color: rgb(127, 208, 255);
  }
}
</style>