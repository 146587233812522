import request from '@/../utils/request'
const api_name = '/iotechserver/jurisCompanyAccount'

export default {
    // 通过公司id返回人员列表
    getJurisCompanyAccount(ids) {
        return request({
            url: `${api_name}/getJurisCompanyAccount/${ids}`,
            method: 'get',
        })
    },
     // 添加用户
     addJurisCompanyAccount(jurisCompanyAccountVo) {
        return request({
            url: `${api_name}/addJurisCompanyAccount`,
            method: 'post',
            data: jurisCompanyAccountVo
        })
    },
    // -----增加——>util_inclusion_relation-----
    addJurisUtilInclusionRelation(JurisUtilInclusionRelation) {
        return request({
            url: `${api_name}/addJurisUtilInclusionRelation`,
            method: 'post',
            data: {
                ids: JurisUtilInclusionRelation["ids"],
                userName: JurisUtilInclusionRelation["name"],
                bigSmallSystemInfo: JurisUtilInclusionRelation["bigSmallSystemInfo"]
            }
        })
    },

     //  查看所有权限
     getAllCommonJuris(ids, name) {
        return request({
            url: `${api_name}/getAllCommonJuris/${ids}/${name}`,
            method: 'get',
        })
    },
    // 删除用户
    deleteJurisCompanyAccount(name, ids) {
        return request({
            url: `${api_name}/deleteJurisCompanyAccount/${name}/${ids}`,
            method: 'delete'
        })
    },
}